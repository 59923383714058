
const ALLOWED_SLUGS = ["inn-salzach", "passau", "freyung-grafenau"]

export default {
    name: "RegionCenterWrapper",
    props: {
        /** Full Region Object */
        region: {
            type: Object,
            required: true,
        },
    },
    computed: {
        hasCustomHeader() {
            return ALLOWED_SLUGS.includes(this.region.slug)
        },
        component() {
            return () =>
                import(`@/components/region/${this.region.slug}/RegionCenter`)
        },
    },
}
