// useCounter.js

export default function useTargetYear() {
    function getTargetYear() {
        const curDate = new Date()
        const curYear = curDate.getFullYear()
        const nextYear = parseInt(curYear) + 1
        if (curDate.getMonth() >= 8) {
            return curYear + "/" + nextYear
        } else {
            return curYear
        }
    }
    return {
        getTargetYear,
    }
}
