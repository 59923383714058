/* Format: slug: require(image) */
const ogImages = {
    landshut: {
        img: require("@/static/region/landshut/og_image.png"),
        type: "image/png",
    },
    altmuehlfranken: {
        img: require("@/static/region/altmuehlfranken/og_image.png"),
        type: "image/png",
    },
}

export default {
    methods: {
        getOgImage() {
            const slug = this.$route.params?.slug
            return [
                {
                    hid: "og:image",
                    name: "og:image",
                    content: ogImages[slug]?.img,
                },
                {
                    hid: "og:image:type",
                    name: "og:image:type",
                    content: ogImages[slug]?.type,
                },
                {
                    hid: "og:image:alt",
                    name: "og:image:alt",
                    content: "Region " + slug + " logo",
                },
            ]
        },
    },
}
