
import OPicture from "@/components/base/image/OPicture"
import { mdiHomeCity } from "@mdi/js"
export default {
    name: "BusinessLogo",
    components: { OPicture },
    props: {
        business: {
            default: null,
            type: Object,
        },
        size: {
            default: 80,
            type: Number,
        },
        rounded: {
            default: false,
            type: Boolean,
        },
        margin: {
            default: "",
            type: String,
        },
        padding: {
            default: 0,
            type: Number,
        },
        flat: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: Boolean,
            default: false,
        },
    },
    icons: { mdiHomeCity },
    computed: {
        styles() {
            return {
                width: `${this.size}px`,
                height: `${this.size}px`,
                padding: this.padding ? `${this.padding}px` : undefined,
            }
        },
        logo() {
            return this.business?.logo
        },
        alt() {
            return this.business?.name ?? "Firmenname"
        },
    },
}
