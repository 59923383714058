
import Card from "@/components/base/card/Card"
import Button from "@/components/base/Button"
import { mdiCheckCircleOutline } from "@mdi/js"

export default {
    name: "SchoolBaseCard",
    components: { Card, Button },
    icons: {
        mdiCheckCircleOutline,
    },
    props: {
        school: {
            type: Object,
            required: true,
        },
    },
    computed: {
        hasSchool() {
            return this.school.has_school
        },
        location() {
            if (this.school.street && this.school.city) {
                return `${this.school.city}, ${this.school.street}`
            }
            return "In der Nähe"
        },
    },
}
