
import { mdiMagnify } from "@mdi/js"
export default {
    name: "OabatTextFieldSearchIcon",
    props: {
        icon: {
            type: String,
            default: mdiMagnify,
        },
    },
}
