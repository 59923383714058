import imageService from "@/services/image.service"

export const ARTICLE_PROJECT = "project"
export const ARTICLE_EVENT = "event"
export const ARTICLE_TRAINEESHIP = "traineeship_news"

export function getArticle(slug) {
    return this.get(`/api/v2/articles/${slug}`)
}

export function getArticles({ type, channel }) {
    return this.get("/api/v2/articles", { type, channel })
}

export function deleteArticle(slug) {
    return this.del(`/api/v2/articles/${slug}`)
}

export function createArticle(article) {
    if (article.image?.id) {
        article.image_id = article.image.id
    }
    if (article.url) {
        if (!article.url.startsWith("http")) {
            article.url = "https://" + article.url
        }
    }
    return this.post("/api/v2/articles", article)
}

export function updateArticle(slug, article) {
    if (article.image?.id) {
        article.image_id = article.image.id
    }
    if (article.url) {
        if (!article.url.startsWith("http")) {
            article.url = "https://" + article.url
        }
    }
    return this.put(`/api/v2/articles/${slug}`, article)
}

export function getDefaultArticle(type) {
    return {
        type,
        image: "",
        title: "",
        tags: [],
        start_at: null,
        end_at: null,
        published_at: "",
        url: "",
        phone_number: "",
        email: "",
        contact_person: "",
        content_blocks: [
            {
                title: "",
                text: "",
                media: [],
            },
        ],
    }
}

/** Adds uids to contentblocks, converts object images to class instance */
export function addDataAfterLocalStorage(article) {
    const newArticle = JSON.parse(JSON.stringify(article))
    newArticle.content_blocks = newArticle.content_blocks.map(
        (block, index) => {
            return {
                ...block,
                uid: index,
                media: block.media.map((media) => {
                    return imageService.asClass(media)
                }),
            }
        }
    )
    return newArticle
}

export function clearDataForLocalStorage(article) {
    const newArticle = JSON.parse(JSON.stringify(article))
    newArticle.image = removeAttrsFromImage(article.image)
    if (!newArticle.image?.id) delete newArticle.image
    newArticle.content_blocks = article.content_blocks.map((block) => {
        return {
            ...block,
            media: block.media.map((media) => {
                return removeAttrsFromImage(media)
            }),
        }
    })
    // remove images without id (during upload)
    newArticle.content_blocks = newArticle.content_blocks.map((block) => {
        return {
            ...block,
            media: block.media.filter((media) => media.id),
        }
    })
    return newArticle
}

function removeAttrsFromImage(image) {
    if (!image?.toJson) return image
    return image.toJSON()
}
