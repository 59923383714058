
export default {
    name: "TypingPlaceholder",
    props: {
        /** Array of strings to be typed */
        suggestions: {
            type: Array,
            required: true,
        },
        /** Delay between each character being added */
        typingSpeed: {
            type: Number,
            default: 100,
        },
        /** Delay between each character being deleted */
        erasingSpeed: {
            type: Number,
            default: 50,
        },
        /** Break between suggestions */
        newTextDelay: {
            type: Number,
            default: 2000,
        },
        /** Delay before initial animation starts */
        initialDelay: {
            type: Number,
            default: 500,
        },
    },
    data: () => ({
        typeStatus: false,
        typeArrayIndex: 0,
        charIndex: 0,
        typeValue: "",
    }),
    computed: {
        currentSuggestion() {
            return this.suggestions[this.typeArrayIndex]
        },
    },
    mounted() {
        setTimeout(this.typeText, this.newTextDelay + this.initialDelay)
    },
    methods: {
        typeText() {
            if (this.charIndex < this.currentSuggestion.length) {
                if (!this.typeStatus) this.typeStatus = true
                this.typeValue += this.currentSuggestion.charAt(this.charIndex)
                this.charIndex++
                setTimeout(this.typeText, this.typingSpeed)
            } else {
                this.typeStatus = false
                setTimeout(this.eraseText, this.newTextDelay)
            }
        },
        eraseText() {
            if (this.charIndex > 0) {
                if (!this.typeStatus) this.typeStatus = true
                this.typeValue = this.currentSuggestion.substring(
                    0,
                    this.charIndex - 1
                )
                this.charIndex--
                setTimeout(this.eraseText, this.erasingSpeed)
            } else {
                this.typeStatus = false
                this.typeArrayIndex++
                if (this.typeArrayIndex >= this.suggestions.length) {
                    this.typeArrayIndex = 0
                }
                setTimeout(this.typeText, this.typingSpeed + 1000)
            }
        },
    },
}
