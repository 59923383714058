
import Button from "@/components/base/Button"
import {
    mdiChevronRight,
    mdiChevronDown,
    mdiChevronLeft,
    mdiChevronUp,
} from "@mdi/js"

export default {
    name: "ViewAllButton",
    components: { Button },
    props: {
        color: {
            type: String,
            default: "black--text",
        },
        fontSize: {
            type: String,
            default: "text-regular",
        },
        /** Direction of the chevron */
        chevron: {
            type: String,
            default: "right",
            validator: (val) =>
                ["right", "down", "left", "up", "none"].includes(val),
        },
    },
    data: () => ({
        mdiChevronRight,
        mdiChevronDown,
        mdiChevronLeft,
        mdiChevronUp,
    }),
    computed: {
        textClasses() {
            return [this.color, this.fontWeight]
        },
        chevronIcon() {
            switch (this.chevron) {
                case "down":
                    return this.mdiChevronDown
                case "left":
                    return this.mdiChevronLeft
                case "up":
                    return this.mdiChevronUp
                default:
                    return this.mdiChevronRight
            }
        },
    },
}
