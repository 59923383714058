
import SchoolBaseCard from "@/components/school/SchoolBaseCard"
import Button from "@/components/base/Button"

import { routeToRegionSchoolOverview } from "@/helpers/routing"
import schoolService from "@/services/school.service"
import levenshtein from "@/helpers/levenshtein"
import RegionSchoolModal from "@/components/region/RegionSchoolModal"

export default {
    name: "RegionSchools",
    components: {
        RegionSchoolModal,
        SchoolBaseCard,
        Button,
        Modal: () => import("@/components/base/modal/Modal"),
    },
    props: {
        /** Region Object */
        region: {
            type: Object,
            required: true,
        },
        /** Adds button and hides elements after 2 rows */
        preview: {
            type: Boolean,
            default: false,
        },
        /** Search query filter */
        filter: {},
        v2: {
            type: Boolean,
            default: false,
        },
        modal: {
            type: Boolean,
            default: false,
        },
    },
    async fetch() {
        const schools = await schoolService.getBaseSchools({
            regionSlug: this.region.slug,
        })
        const missingSchools = this.regionSchools.filter(
            (s1) => !schools.some((s2) => s1.slug === s2.slug)
        )
        this.registeredSchools = schools.filter((school) => school.has_school)
        this.schools.push(...missingSchools)
        this.totalSchools = schools.length
        this.allSchools = schools
        if (this.preview) {
            this.schools.push(...schools.slice(0, 15))
        } else {
            this.schools.push(...schools)
        }
    },
    data: () => ({
        schools: [],
        registeredSchools: [],
        allSchools: [],
        totalSchools: null,
        selectedSchool: null,
        showAllModal: false,
    }),
    computed: {
        routeToOverview() {
            if (this.modal) return undefined
            return routeToRegionSchoolOverview({
                slug: this.region.slug,
                v2: this.v2,
            })
        },
        /** Filters schools by score
         *
         * scoring being computed with:
         * stopword removal, levenshtein, startsWith, includes, word-based includes
         *
         * Expected value for matches: >3
         */
        filteredSchools() {
            let scoredSchools
            if (!this.filter) {
                scoredSchools = this.schools.map((school) => ({
                    school,
                    score: school.has_school ? 101 : 100,
                }))
            } else {
                const fillCharactersRegex = new RegExp("[.\\-(),]*", "g")
                const filter = this.filter
                    .toLowerCase()
                    .replaceAll(fillCharactersRegex, "")

                scoredSchools = this.schools.map((school) => {
                    const schoolName = school.name
                        .toLowerCase()
                        .replaceAll(fillCharactersRegex, "")
                    let score = schoolName.length - 5
                    score -= levenshtein(schoolName, filter)

                    if (schoolName.includes(filter)) score += 5
                    if (schoolName.startsWith(filter)) score += 10
                    const schoolWords = schoolName.split(" ")
                    const filterWords = filter.split(" ")
                    score += filterWords.reduce((prev, word) => {
                        if (
                            schoolWords.some(
                                (w) => w.length > 3 && w.startsWith(word)
                            )
                        )
                            return prev + 5
                        if (schoolWords.includes(word)) return prev + 2
                        return prev
                    }, 0)
                    return {
                        school,
                        score,
                    }
                })
            }

            return scoredSchools.sort((e1, e2) => e2.score - e1.score)
        },
        regionSchools() {
            if (!this.region?.schools) return []
            return this.region.schools.map((school) => {
                return {
                    city: school.city,
                    street: school.primary_address.street,
                    has_school: true,
                    id: school.id,
                    slug: school.slug,
                    name: school.name,
                }
            })
        },
        maxScore() {
            if (this.filteredSchools?.length > 0) {
                return this.filteredSchools[0].score
            }
            return 0
        },
    },
    methods: {
        onSchoolClick(school) {
            if (school.has_school) {
                this.selectedSchool = school.slug
            }
        },
        onShowAllClick() {
            if (!this.modal) return
            this.showAllModal = true
        },
        isExcluded(score) {
            if (!this.filter) return false
            if (this.filter.length <= 3) return false
            if (score <= 3) return true
            return score < this.maxScore - 5
        },
    },
}
