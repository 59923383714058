
import Modal from "@/components/base/modal/Modal"
import OPicture from "@/components/base/image/OPicture"
import Button from "@/components/base/Button"
import schoolService from "@/services/school.service"
import { routeToBulletinBoard } from "@/helpers/routing"
import ProgressCircular from "@/components/base/ProgressCircular"
export default {
    name: "RegionSchoolModal",
    components: { ProgressCircular, Button, OPicture, Modal },
    props: {
        schoolSlug: {
            type: String,
            required: true,
        },
    },
    data: () => ({
        school: null,
        routeToBulletinBoard,
    }),
    async created() {
        this.school = await schoolService.getSchool(this.schoolSlug)
    },
}
