
const RETINA_SUPPORTED_SIZES = [
    "job",
    "bus",
    "job_detail",
    "200_fit",
    "400_fit",
]

export default {
    name: "OPicture",
    props: {
        loading: {
            default: "lazy",
            type: String,
            validator: (val) => ["lazy", "eager"].includes(val),
        },
        alt: {
            required: true,
            type: String,
        },
        width: {
            default: null,
            type: Number,
        },
        height: {
            default: null,
            type: Number,
        },
        /**
         * image which will be used or null if a placeholder should be used
         */
        image: {
            type: [Object],
            default: null,
        },
        /**
         * size of the image or placeholder which should be used
         * <format>: <width x height>, <fit_algorithm>, <retina_size_exists>
         * job:         370x412, fit, @2
         * job_detail:  800x1067, fit, @2
         * bus:         370x180, fit, @2
         * 0:           2000x2000,
         * 1:           1000x1000,
         * 2:           500x500,
         * 3:           200x200,
         * 200_fit:     200x200, fit, @2
         * 400_fit:     400x400, fit, @2
         * 800_fit:     800x800, fit
         */
        size: {
            required: true,
            type: String,
            validator: (val) =>
                [
                    "job",
                    "job_detail",
                    "bus",
                    "0",
                    "1",
                    "2",
                    "3",
                    "p",
                    "200_fit",
                    "400_fit",
                    "800_fit",
                ].includes(val),
        },
        /**
         * defines the object fit of the image
         */
        fit: {
            default: "cover",
            type: String,
            validator: (val) =>
                ["fill", "contain", "cover", "none", "scale-down"].includes(
                    val
                ),
        },
        draggable: {
            type: Boolean,
            default: false,
        },
        skeleton: {
            type: Boolean,
            default: false,
        },
        fallbackSource: {
            type: String,
            required: false,
        },
    },
    computed: {
        localImageFormat() {
            if (this.isLocalImageUsed)
                return this.image.local.split(";")[0].split("/")[1]
            return undefined
        },
        isLocalImageUsed() {
            return !this.image?.urls && this.image?.local
        },
        aspectRatioStyle() {
            if (
                !this.internalWidth ||
                !this.internalHeight ||
                this.fit != "contain"
            )
                return {}
            const aspectRatio = this.internalWidth / this.internalHeight
            return {
                aspectRatio: aspectRatio.toString(),
            }
        },
        styleAttribute() {
            return {
                objectFit: this.fit,
            }
        },
        internalHeight() {
            if (!this.height && this.width && this.image?.height > 0) {
                const aspectRatio = this.image?.width / this.image?.height
                return this.width / aspectRatio
            }
            return this.height ?? this.image?.height
        },
        internalWidth() {
            if (!this.width && this.height && this.image?.height) {
                const aspectRatio = this.image?.width / this.image?.height
                return this.height * aspectRatio
            }
            return this.width ?? this.image?.width
        },
    },

    methods: {
        srcset(format) {
            const defaultSrc = this.imageSource(format, this.size)

            if (RETINA_SUPPORTED_SIZES.includes(this.size)) {
                let retinaSrc = null
                if (this.size === "200_fit")
                    retinaSrc = this.imageSource(format, "400_fit")
                else if (this.size === "400_fit")
                    retinaSrc = this.imageSource(format, "800_fit")
                else retinaSrc = this.imageSource(format, this.size + "@2")
                return `${defaultSrc} 1x, ${retinaSrc} 2x`
            } else {
                return `${defaultSrc} 1x`
            }
        },
        imageSource(format, size) {
            let fileSuffix = `${size}.${format}`
            if (this.image?.urls?.[fileSuffix] || this.image?.local) {
                if (!this.image?.urls?.[fileSuffix]) return this.image.local
                return this.image.urls[fileSuffix]
            } else {
                return `/placeholders/${fileSuffix}`
            }
        },
        isEmpty(file) {
            return (
                file &&
                Object.keys(file).length === 0 &&
                Object.getPrototypeOf(file) === Object.prototype
            )
        },
    },
}
