import {
    JOB_V3,
    JOB_AI,
    PUBLISH_BUSINESS,
    PUBLISH_APPRENTICESHIP,
    PUBLISH_TRAINEESHIP,
    JOB_EINBLICK,
    INVITE_SCHOOL,
    JOB_CI,
    JOB_EXTERNAL_APPLICATION,
    MAIL2CHAT,
    SHOW_OWN_JOBS,
} from "@/enums/permissions"

import {
    ARTICLE_PROJECT,
    ARTICLE_EVENT,
    ARTICLE_TRAINEESHIP,
} from "@/services/v2/article.service"

export default {
    mapValue: (mapping) => (value) => {
        let result = mapping.find((obj) => {
            return obj.value === value
        })
        return result?.text
    },
    mapText: (mapping) => (text) => {
        let result = mapping.find((obj) => {
            return obj.text === text
        })
        return result?.value
    },
}

export const jobTypeMapping = [
    { text: "Ausbildung", value: 1 },
    { text: "Praktikum", value: 2 },
    { text: "Job", value: 3 },
]

export const countryMapping = [
    { text: "Deutschland", value: "DE" },
    { text: "Österreich", value: "AT" },
    {
        text: "Schweiz",
        value: "CH",
    },
]

export const classMapping = [
    { text: "6. Klasse", value: "6" },
    { text: "7. Klasse", value: "7" },
    { text: "8. Klasse", value: "8" },
    { text: "9. Klasse", value: "9" },
    { text: "10. Klasse", value: "10" },
    { text: "11. Klasse", value: "11" },
    { text: "12. Klasse", value: "12" },
    { text: "13. Klasse", value: "13" },
    { text: "Ich gehe nicht mehr zur Schule", value: "-1" },
]

export const genderMapping = [
    { text: "Frau", value: "female" },
    { text: "Herr", value: "male" },
    { text: "Divers", value: "else" },
]

export const employeeMapping = [
    { text: "1-25", value: 1 },
    { text: "26-75", value: 26 },
    { text: "76-150", value: 76 },
    { text: "151-250", value: 151 },
    { text: "251-500", value: 251 },
    { text: "> 500", value: 501 },
]

export const graduationMapping = [
    { text: "Kein Schulabschluss", value: "none" },
    { text: "Mittelschulabschluss", value: "main" },
    { text: "Qualifizierender Mittelschulabschluss", value: "quali" },
    { text: "Mittlerer Schulabschluss", value: "mittel" },
    { text: "Fachabitur", value: "fos" },
    { text: "Abitur", value: "abi" },
    { text: "Bachelor", value: "bachelor" },
    { text: "Master", value: "master" },
    { text: "Diplom", value: "diploma" },
]

export const graduationMappingShort = [
    { text: "Für jeden frei", value: "none" },
    { text: "Mittelschulabschluss", value: "main" },
    { text: "Mittelschule - Quali", value: "quali" },
    { text: "Mittlerer Abschluss", value: "mittel" },
    { text: "Fachabitur", value: "fos" },
    { text: "Abitur", value: "abi" },
]

export const graduationMappingJob = [
    ...graduationMappingShort,
    { text: "Bachelor", value: "bachelor" },
    { text: "Master", value: "master" },
    { text: "Diplom", value: "diploma" },
]

export const graduationCategory = [
    { text: "high school", value: "main" },
    { text: "high school", value: "quali" },
    { text: "associate degree", value: "mittel" },
    { text: "bachelor degree", value: "fos" },
    { text: "associate degree", value: "abi" },
    { text: "bachelor degree", value: "bachelor" },
    { text: "bachelor degree", value: "master" },
    { text: "bachelor degree", value: "diploma" },
]

/** ATTENTION: years not supported in backend! */
export const durationTypePlural = [
    { text: "Tage", value: "days" },
    { text: "Wochen", value: "weeks" },
    { text: "Monate", value: "months" },
    { text: "Jahre", value: "years" },
]
/** ATTENTION: years not supported in backend! */
export const durationTypeSingular = [
    { text: "Tag", value: "days" },
    { text: "Woche", value: "weeks" },
    { text: "Monat", value: "months" },
    { text: "Jahr", value: "years" },
]

export const crafts = [
    { text: "IT & Technik", value: "it" },
    { text: "Handwerk", value: "craft" },
    { text: "Verwaltung & Büro", value: "office" },
    { text: "Menschen helfen", value: "social" },
    { text: "Verkauf & Handel", value: "sales" },
    { text: "Kunst & Unterhaltung", value: "art" },
]

export const articleTypes = [
    { text: "Projekt", value: ARTICLE_PROJECT },
    { text: "Event", value: ARTICLE_EVENT },
    { text: "Praktikumswochen", value: ARTICLE_TRAINEESHIP },
]

export const billingPeriod = [
    { text: "Tag", value: "daily" },
    { text: "Monat", value: "monthly" },
    { text: "Jahr", value: "yearly" },
]

export const imageMediaFormat = [
    { text: "image/jpeg", value: "jpg" },
    { text: "image/png", value: "png" },
    { text: "image/gif", value: "gif" },
    { text: "image/webp", value: "webp" },
    { text: "image/svg+xml", value: "svg" },
    { text: "image/x-icon", value: "ico" },
]

/** NON-default format mappings below */

export const billingPeriodFactor = {
    daily: {
        daily: 1,
        monthly: 30,
        yearly: 365,
    },
    monthly: {
        daily: 1 / 30,
        monthly: 1,
        yearly: 12,
    },
    yearly: {
        daily: 1 / 356,
        monthly: 1 / 12,
        yearly: 1,
    },
}

export const productIconMapping = [
    {
        slug: "job-teaser",
        icon: require("@/assets/icons/store/icon_media.svg"),
    },
    {
        slug: "job-hide-oabat",
        icon: require("@/assets/icons/store/icon_remove_ads.svg"),
    },
    {
        slug: "job-theme",
        icon: require("@/assets/icons/store/icon_theme.svg"),
    },
    {
        slug: "job-external-page",
        icon: require("@/assets/icons/store/icon_hyperlink.svg"),
    },
    {
        slug: "job-mail2chat",
        icon: require("@/assets/icons/store/icon_mail.svg"),
    },
    {
        slug: "job-external-integration",
        icon: require("@/assets/icons/store/icon_internet.svg"),
    },
    {
        slug: "job-google-jobs",
        icon: require("@/assets/icons/store/icon_google_G_logo.svg"),
    },
    {
        slug: "job-no-ads",
        icon: require("@/assets/icons/store/icon_lock.svg"),
    },
]

export const productReachFactor = {
    reach: {
        base: 20,
        "job-google-jobs": 50,
        "job-external-integration": 30,
        "job-no-ads": 10,
    },
    views: {
        base: 15,
        "job-google-jobs": 50,
        "job-external-integration": 20,
        "job-no-ads": 15,
    },
    clicks: {
        base: 10,
        "job-google-jobs": 45,
        "job-external-integration": 25,
        "job-no-ads": 20,
    },
    applications: {
        base: 5,
        "job-google-jobs": 15,
        "job-external-integration": 60,
        "job-no-ads": 20,
    },
}

export function jobScoreToColor(score) {
    if (score === undefined || score === null)
        return { code: "#808080", color: "grey" }
    if (score >= 90) return { code: "#0dc85d", color: "green" }
    else if (score >= 50) return { code: "#FFB41D", color: "yellow" }
    else return { code: "#FF4759", color: "red" }
}

export function productToPermissions(product_slug) {
    let type = "basic"
    if (product_slug.includes("einblick-pro")) type = "einblick-pro"
    else if (product_slug.includes("einblick")) type = "einblick"
    const basic_permissions = [
        JOB_V3,
        JOB_AI,
        PUBLISH_BUSINESS,
        PUBLISH_APPRENTICESHIP,
        PUBLISH_TRAINEESHIP,
    ]
    const einblick_permissions = basic_permissions.concat([
        JOB_EINBLICK,
        INVITE_SCHOOL,
    ])
    const einblick_pro_permissions = einblick_permissions.concat([
        JOB_CI,
        JOB_EXTERNAL_APPLICATION,
        MAIL2CHAT,
        SHOW_OWN_JOBS,
    ])
    const mapping = {
        basic: basic_permissions,
        einblick: einblick_permissions,
        "einblick-pro": einblick_pro_permissions,
    }
    return mapping[type]
}

export function productToNumberOfJobs(product_slug) {
    if (!product_slug || product_slug.includes("-s")) return "bis zu 3"
    else if (product_slug.includes("-m")) return "bis zu 6"
    else if (product_slug.includes("-l")) return "bis zu 10"
    else if (product_slug.includes("-xl")) return "mehr als 10"
}

/** Formats number to string with fixed amount of decimalPlaces */
export function toFixedDecimal(num, fixed = 2) {
    num = parseFloat(num).toPrecision(12)
    let re = new RegExp("^-?\\d+(?:.\\d{0," + (fixed || -1) + "})?")
    return num.toString().match(re)[0]
}

export const channelToFairName = {
    "r-passauer-ausbildungsmesse": "Passauer Ausbildungsmesse",
}

export function mapBusinessResponseTime(time) {
    let oneHour = 60 * 60
    let tenMins = 10 * 60
    if (time <= tenMins + oneHour) return "< 1"
    if (time <= tenMins + 2 * oneHour) return "< 2"
    if (time <= tenMins + 4 * oneHour) return "< 4"
    if (time <= tenMins + 8 * oneHour) return "< 8"
    if (time <= tenMins + 12 * oneHour) return "< 24"
    return ">24"
}

export function mapBusinessResponseTimeLong(time) {
    let oneHour = 60 * 60
    let tenMins = 10 * 60
    if (time <= tenMins + oneHour) return "unter 1"
    if (time <= tenMins + 2 * oneHour) return "unter 2"
    if (time <= tenMins + 4 * oneHour) return "unter 4"
    if (time <= tenMins + 8 * oneHour) return "unter 8"
    if (time <= tenMins + 12 * oneHour) return "unter 24"
    return "über 24"
}
