
import SearchResult from "@/components/search/v2/SearchResult"
import RegionHeader from "@/components/region/RegionHeader"
import RegionCenter from "@/components/region/RegionCenter"
import ViewAllButton from "@/components/base/button/ViewAllButton"
import OabatTextFieldSearchIcon from "@/components/base/design/OabatTextFieldSearchIcon"
import TypingPlaceholder from "@/components/base/input/TypingPlaceholder"
import BusinessLogo from "@/components/business/BusinessLogo"
import RegionSchools from "@/components/region/RegionSchools"

import { mdiFilter, mdiChevronDown } from "@mdi/js"
import { location, filters } from "@/assets/icons/js"
import { mapGetters } from "vuex"

import useTargetYear from "@/helpers/useTargetYear"
import regionService from "@/services/region.service"
import regionSearchRouting from "@/models/regionSearchRouting"
import searchService from "@/services/search.service"
import regionMeta from "@/mixins/regionMeta"
const debounce = require("lodash.debounce")

export default {
    name: "RegionSlug",
    components: {
        SearchResult,
        RegionHeader,
        RegionCenter,
        ViewAllButton,
        OabatTextFieldSearchIcon,
        TypingPlaceholder,
        BusinessLogo,
        RegionSchools,
    },
    layout: "modern",
    meta: {
        footer: {
            showMobileAuth: true,
            showPowered: true,
        },
    },
    mixins: [regionMeta],
    async asyncData({ route, store }) {
        const region = await regionService.getRegion("freyung-grafenau")
        await store.dispatch("search/init", {
            categories: regionSearchRouting.getRouting(route),
            selectedCategory: "Alles",
            locationSlug: "freyung-grafenau",
            query: route.query,
            channel: region.channel.slug,
            type: "region",
        })

        const { result } = await searchService.performSearch({
            category: "Unternehmen",
            channel: region.channel.slug,
        })
        const previewBusinesses = result.data.slice(0, 3)
        return { region, totalBusinesses: result.total, previewBusinesses }
    },
    computed: {
        ...mapGetters("search", { searchRoute: "route" }),
        metaTitle() {
            return `Ausbildung made in FRG: Ausbildung, Praktika und Betriebe`
        },
        metaDescription() {
            return `Entdecke freie Ausbildungsplätze, Schülerpraktika und die besten Firmen in der Region Freyung-Grafenau. Starte deine Zukunft und finde deinen Traumberuf.`
        },
    },
    created() {
        this.debouncedSearchBusiness = debounce(this.searchBusiness, 300, {
            leading: false,
            trailing: true,
        })
    },
    methods: {
        onSearchInput() {
            if (!this.businessSearch.input) {
                this.businessSearch.result = null
                this.businessSearch.loading = false
                return
            }
            this.businessSearch.loading = true
            this.debouncedSearchBusiness()
        },
        async searchBusiness() {
            if (!this.businessSearch.input) {
                this.businessSearch.result = null
                this.businessSearch.loading = false
                return
            }
            let searchParams = {
                category: "Unternehmen",
                channel: this.region.channel.slug,
                search: this.businessSearch.input,
            }

            const result = await this.$axios
                .$get("/api/search/business", {
                    params: searchParams,
                })
                .catch((err) => console.warn(err))
            this.businessSearch.result = result.data
            this.businessSearch.loading = false
        },
    },
    provide() {
        return {
            tagColors: ["#f4ab37", "#2d6a85", "#aebc5c"],
        }
    },
    data: () => ({
        icons: {
            mdiChevronDown,
            location,
            filters,
            mdiFilter,
        },
        getTargetYear: useTargetYear().getTargetYear,
        totalBusinesses: 0,
        previewLogos: [],
        businessSearch: {
            suggestions: ["Sparkasse", "Bäcker", "Hirschmann", "Aptar", "AVS"],
            input: "",
            showSuggestions: true,
            loading: false,
            result: null,
            debouncedSearchBusiness: null,
        },
    }),
    head() {
        return {
            title: this.metaTitle,
            meta: [
                {
                    hid: "description",
                    name: "description",
                    content: this.metaDescription,
                },
                {
                    hid: "og:title",
                    name: "og:title",
                    content: this.metaTitle,
                },
                {
                    hid: "og:description",
                    name: "og:description",
                    content: this.metaDescription,
                },
                ...this.getOgImage(),
            ],
        }
    },
}
